<template>
    <v-row justify="center">
        <v-dialog
            v-model="showModal"
            min-height="300" max-width="500"
        >
       
        <v-card  class="text-center" min-height="300" max-width="500" >
           
            <v-icon class="mt-5" size="120px" color="#FFCE8B">
                <!-- mdi-help-circle -->
            </v-icon>
            <v-col >
                <!-- <h2>{{textTitle}}</h2> -->
            </v-col>
            <v-col >
                <h2>{{textTitle}}</h2>
            </v-col>
            <v-col>
                {{textDetail}}     
            </v-col>
            <v-col>
                <v-btn
                    width="140"
                    class="white--text"
                    color="#69A5FF"
                    @click="onOpen()"
                >
                ติดต่อเจ้าหน้าที่
                </v-btn>
            </v-col>
            <v-col>
                <v-btn
                    style="margin-top: -1.5rem ;" 
                    text
                    @click="onClose()"
                >
                ยกเลิก
                </v-btn>
                
            </v-col>

        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {
        name : "ModalNotify",
        props:{
            showModal : Boolean,
            textTitle : String , 
            textDetail : String , 
            openWeb : String  
        },
        created(){

        },
        methods:{
            onClose(){
                this.$emit('onClose')
            },
            onOpen(){
                window.open(this.openWeb , '_blank')
                return 0;
            }
        },
    }
</script>

