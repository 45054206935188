<template>
    <div>
      <v-form @submit.prevent="submitUpdateSection" :id="`check-create-cmu-exam-form-${courseData._id}`">
      <v-dialog
        v-model="dialog"
        :width="dialogSize"
        scrollable
      >
     
        <v-card>
          <v-card-title>
              <b>Edit Selection</b>
              <v-spacer></v-spacer>
              <v-btn icon @click="clear() " >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
                  
              <v-col style="margin-top: 2rem;">
                <v-row >
                  <template>
                    <h3 style="margin-top:0.25rem; margin-right: 0.5rem; margin-left: 0.5rem;">หลักสูตรที่ดูแล</h3>
                    <v-checkbox
                      class="mt-0"
                      v-model="checkAll"
                      @change="selectAll($event)"
                      label="เลือกทั้งหมด"
                      value="checkAll"
                    ></v-checkbox>
                    <div class="break"></div>
                  </template>
                </v-row>
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="9">
                    <template v-for="(section,i) in sectionArr">
                        <v-checkbox
                          class="mt-0"
                          v-model="selected"
                          :label="filterSection(section)"
                          :value="section"
                          :key="`checkbox-${i}`"
                        ></v-checkbox>
                        <div class="break" :key="`break-${i}`"></div>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
  
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="dark"
              text
              @click="clear()"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="#69A5FF"
              class="white--text"
              type="submit"
              :form="`check-create-cmu-exam-form-${courseData._id}`"
              :key="`submit-btn-${courseData._id}`"
            >
              บันทึก
            </v-btn>
            
          </v-card-actions> 
          
          
        </v-card>
      </v-dialog>
      
      
      </v-form>
    </div>
  </template>
  
  <script>
    const config = require(`@/config`)
    import Swal from "sweetalert2"
  
      export default {
        name: 'EditCourseModal',
        props: {
          courseData: {
            type : Object
          },
          openModal: Boolean,
          courseAllSection :{
            type : Object
          },
          onClose : Function
        },
        computed: {
        },
        data () {
          return {
            dialog: false,
            dialogSize: 500,
            selected: [],
            sectionArr: [],
            section: [] ,
            selection : [] ,
            adminEmail : "" ,
            checkAll :  true ,
            rules: {
                adminEmail: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
          }
        },
        created (){
          this.createSectionArr(this.courseAllSection)
        },
        components: {
        },
       
        watch: {
            courseAllSection : function (newVal) {
                this.createSectionArr(newVal)
            },
            courseData : function(newVal ){
                if(newVal){
                    this.initSectionArr(newVal)
                }
                
            },
            openModal: function (value) {
                console.log("openModal", value)
                if(value){
                    this.dialog = true
                }
            },
            dialog: function () {
                this.$emit('modalClose', false)
            },
            selected: function(value){
              console.log("value :" , value)
            }
          
          
        },
        methods: {
            createSectionArr (courseDataAll) {
                if(courseDataAll){
                    this.sectionArr = []
                    for(let i=0;i<courseDataAll.sections.length;i++){
                        this.sectionArr.push(courseDataAll.sections[i])
                    }
                }
                console.log("sectionArr" , this.sectionArr)
            },
            initSectionArr(sectionSelection){
                if(sectionSelection.sections){
                    this.selected = []
                    for(let i=0;i<sectionSelection.sections.length;i++){
                        if(String(sectionSelection.sections[i]).substring(0 , 3) === "Lab"){
                          this.selected.push('000'+String(sectionSelection.sections[i]).substring(4))
                        }else{
                          this.selected.push(String(sectionSelection.sections[i]).substring(4)+'000')
                        }
                        if(i+1 >= this.courseAllSection.sections.length){
                            this.checkAll = "checkAll"
                        }
                    }
                }

            },
            async submitUpdateSection() {
                if(this.selected.length > 0 ){
                    const createData = {
                        "courseId": this.courseData?._id,
                        "termId" : config.lmsConfig.mango.termId,
                        "section": this.selected
                    }
                    this.$emit('modalUpdate', createData )
                    this.clear()
                    this.selected = []
                }else{
                    Swal.fire({
                        icon: 'warning',
                        title: 'กรุณาเลือก Section',
                    })
                }
            },
            clear(){
                this.dialog = false
                this.selected = [] ;
                this.selection = [] ;
                this.checkAll = ""  ;
                this.adminEmail = "" ;
                this.$emit("onClose")
            },
            selectAll(event){
                console.log("event :>" , event)
                let selected = []
                if (event) {
                    this.sectionArr.forEach(function (section) {
                    selected.push(section)
                    })
                }
                this.selected = selected
            },
            filterSection (section) {
                let sectionLec = section.substring(0, 3)
                let sectionLab = section.substring(3)
                if (sectionLec === '000' && sectionLab !== '000') {
                    return `Lab.${sectionLab}`
                }else if(sectionLec !== '000' && sectionLab === '000'){
                    return `Lec.${sectionLec}`
                }
                return 0
            },
        
         
        },
      }
  </script>